import React from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import useFetch from "../../../../hooks/useFetch"
import { Formik } from "formik"
import FormikInput from "../../../../components/Inputs/FormikInput"
import { Col, Row } from "reactstrap"
import { useAuth } from "../../../../auth/useAuth"
import { Link, navigate } from "gatsby"
import { v4 as uuid } from "uuid"
import { COMMON_IMAGES } from "../../../../templates/EditSpace/Dropzones/constants"
import Image from "../../../../templates/EditSpace/Dropzones/image"
import GooglePlacesSearchBox from "../../../../templates/EditSpace/fields/GooglePlacesSearchBox"
import { toast } from "react-toastify"
import { torontoAreas } from "../../members/[id]"

const AddCafe = ({ params }) => {
  const { makeRequest } = useAuth() || {}

  const { progress, data } = useFetch(
    `worksimply-approved-cafes/view`,
    "post",
    {
      id: params.id,
    }
  )

  const handleCheck = (value, neighbourhoods = [], checkAll = false) => {
    if (checkAll) {
      const mainArea = torontoAreas.find((area) => area.name === value)

      const alreadySelectedAreas = [...neighbourhoods].filter(
        (item) => !!mainArea?.items?.includes(item)
      )

      const newAreas = [...neighbourhoods].filter(
        (item) => !mainArea?.items?.includes(item)
      )

      if (alreadySelectedAreas?.length === mainArea?.items?.length) {
        return [...newAreas]
      } else {
        return [...newAreas, ...mainArea?.items]
      }
    } else {
      const alreadyExists = neighbourhoods.find((item) => item === value)

      if (alreadyExists) {
        return [...neighbourhoods].filter((item) => item !== value)
      } else {
        return [...neighbourhoods, value]
      }
    }
  }

  return (
    <>
      <SEO title="Update Cafe" />
      <Layout title={"Update Cafe"} progress={progress}>
        <Link
          to={"/admin/approved-cafes"}
          className={"btn btn-light btn-sm mb-4"}
        >
          Back
        </Link>
        <Row>
          <Col lg={8} xl={6}>
            <Formik
              initialValues={{
                name: data?.cafe?.name || "",
                lat: data?.cafe?.lat || "",
                lng: data?.cafe?.lng || "",
                address: data?.cafe?.address || "",
                shortAddress: data?.cafe?.shortAddress || "",
                placeId: data?.cafe?.placeId || "",
                internetSpeed: data?.cafe?.internetSpeed || "",
                goodForCalls: data?.cafe?.goodForCalls || "",
                outletsAvailable: data?.cafe?.outletsAvailable || "",
                noiseLevel: data?.cafe?.noiseLevel || "",
                wifiName: data?.cafe?.wifiName || "",
                wifiPassword: data?.cafe?.wifiPassword || "",
                neighbourhoods: data?.cafe?.neighbourhoods?.list || [],
              }}
              onSubmit={async (values, { setSubmitting }) => {
                const data = {
                  ...values,
                  neighbourhoods: {
                    list: values?.neighbourhoods,
                  },
                }

                await makeRequest("worksimply-approved-cafes/update", {
                  id: params.id,
                  data,
                })
                setSubmitting(false)
                toast.success("Cafe added")
                navigate("/admin/approved-cafes")
              }}
            >
              {({
                values,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormikInput name={"name"} label={"Name"} />

                  <GooglePlacesSearchBox
                    value={{
                      place_id: data?.cafe?.placeId,
                    }}
                    cols={12}
                    onChange={(value) => {
                      setFieldValue("address", value.address).then(() => null)
                      setFieldValue("shortAddress", value.short_address).then(
                        () => null
                      )
                      setFieldValue("lat", value.lat).then(() => null)
                      setFieldValue("lng", value.lng).then(() => null)
                      setFieldValue("placeId", value.place_id).then(() => null)
                    }}
                  />

                  <ExistingPlaceData values={data?.cafe} />

                  <label htmlFor="images">Images</label>
                  <Image
                    spDataId={data?.cafe?.id}
                    category={COMMON_IMAGES}
                    multiple
                    existingImages={data?.cafe?.images || []}
                    label={""}
                  />

                  <br />

                  <FormikInput
                    name={"internetSpeed"}
                    label={"Internet Speed"}
                    type={"select"}
                    options={[
                      { label: "Please select", value: "" },
                      { label: "Fair (3-10 Mbps)", value: "Fair (3-10 Mbps)" },
                      {
                        label: "Good (10-25 Mbps)",
                        value: "Good (10-25 Mbps)",
                      },
                      {
                        label: "Very Good (25-50 Mbps)",
                        value: "Very Good (25-50 Mbps)",
                      },
                      {
                        label: "Great (50-100 Mbps)",
                        value: "Great (50-100 Mbps)",
                      },
                      {
                        label: "Exceptional (100+ Mbps)",
                        value: "Exceptional (100+ Mbps)",
                      },
                    ]}
                  />
                  <FormikInput
                    name={"goodForCalls"}
                    label={"Good for calls?"}
                    type={"select"}
                    options={[
                      { label: "Please select", value: "" },
                      { label: "Not Suitable", value: "Not Suitable" },
                      {
                        label: "Occasionally Noisy",
                        value: "Occasionally Noisy",
                      },
                      { label: "Background Buzz", value: "Background Buzz" },
                      { label: "Relatively Quiet", value: "Relatively Quiet" },
                      { label: "Quiet & Calm", value: "Quiet & Calm" },
                      {
                        label: "Dedicated Quiet Zones",
                        value: "Dedicated Quiet Zones",
                      },
                    ]}
                  />
                  <FormikInput
                    name={"outletsAvailable"}
                    label={"Outlets available?"}
                    type={"select"}
                    options={[
                      { label: "Please select", value: "" },
                      { label: "No Outlets", value: "No Outlets" },
                      { label: "Few Outlets", value: "Few Outlets" },
                      { label: "Some Outlets", value: "Some Outlets" },
                      { label: "Many Outlets", value: "Many Outlets" },
                    ]}
                  />
                  <FormikInput
                    name={"noiseLevel"}
                    label={"Noise level?"}
                    type={"select"}
                    options={[
                      { label: "Please select", value: "" },
                      { label: "Quiet", value: "Quiet" },
                      { label: "Moderate", value: "Moderate" },
                      { label: "Lively", value: "Lively" },
                      { label: "Noisy", value: "Noisy" },
                    ]}
                  />

                  <FormikInput name={"wifiName"} label={"WiFi Name"} />
                  <FormikInput name={"wifiPassword"} label={"WiFi Password"} />

                  <div className="mb-3">
                    <label htmlFor="">Areas</label>
                    <div className="row">
                      {torontoAreas.map((area) => {
                        const allSelected = !!area?.items?.every((item) =>
                          values?.neighbourhoods?.includes(item)
                        )
                        return (
                          <div className={"col-6 mb-3"} key={area.name}>
                            <label className={"d-flex align-items-center ml-2"}>
                              <input
                                type="checkbox"
                                checked={allSelected}
                                onChange={(e) => {
                                  e.stopPropagation()
                                  const v = handleCheck(
                                    area.name,
                                    values?.neighbourhoods,
                                    true
                                  )
                                  setFieldValue("neighbourhoods", v)
                                }}
                              />
                              <div style={{ marginLeft: 8, fontWeight: 400 }}>
                                {area.name}
                              </div>
                            </label>
                            {area?.items?.map((area) => (
                              <label
                                key={area}
                                className={"d-flex align-items-center ml-4"}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    !!values?.neighbourhoods?.find(
                                      (item) => item === area
                                    )
                                  }
                                  onChange={(value) =>
                                    setFieldValue(
                                      "neighbourhoods",
                                      handleCheck(area, values?.neighbourhoods)
                                    )
                                  }
                                />
                                <div style={{ marginLeft: 8, fontWeight: 400 }}>
                                  {area}
                                </div>
                              </label>
                            ))}
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={"btn btn-primary"}
                  >
                    Save
                  </button>
                </form>
              )}
            </Formik>
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default AddCafe

export const ExistingPlaceData = ({ values }) => {
  if (!values.lat) return null
  return (
    <ul
      className={"ul-unstyled"}
      style={{ listStyleType: "none", padding: 0, marginTop: -16 }}
    >
      <li>Address: {values.address}</li>
      <li>ShortAddress: {values.shortAddress}</li>
      <li>Lat: {values.lat}</li>
      <li>Lng: {values.lng}</li>
    </ul>
  )
}
